import React, { ReactNode } from 'react';

interface TitleProps {
  className?: string;
  children: ReactNode;
}

function Title({ className, children }: TitleProps) {
  return <h1 className={className}>{children}</h1>;
}

export default Title;
