import React, { useRef } from 'react';
import UnorderedList from '../../base/UnorderedList';
import Emphasis from '../../base/Emphasis';
import VideoContainer from '../../base/VideoContainer';
import Figure from '../../base/Figure';

function PatientReportsShowcase() {
  const videoElementRef = useRef<HTMLVideoElement | null>(null);

  return (
    <>
      <UnorderedList
        items={[
          <>
            One report is created in React and the other in Vue 3, but both are
            powered by the same custom component style library written in SASS
            and CSS Module, ensuring a consistent look and feel. No CSS
            framework was used.
          </>,
          <>
            The charts are created with ApexCharts.js and Chart.js. All line
            charts feature subtle gradient fill.
          </>,
          <>
            The bubble data labels on the <Emphasis>Trigger Trends</Emphasis>{' '}
            line chart of the <Emphasis>Triggers & Migraine</Emphasis> report
            were created by injecting programmatically created HTML{' '}
            <code>div</code> element nested in SVG <code>foreignObject</code> so
            that the figures are vertically and horizontally centered within the
            bubbles
          </>
        ]}
      />

      <Figure>
        <VideoContainer>
          <video ref={videoElementRef} controls width="100%">
            <source
              src="/videos/patient-reports-weather-report-bell-curve-with-dynamic-pointer.mov"
              type="video/mp4"
            />
            Sorry, your browser does not support embedded videos.
          </video>
        </VideoContainer>
      </Figure>

      <UnorderedList
        items={[
          <>
            Bell curve is programmatically plotted to a SVG from 200 coordinates
            computed from the normal distribution formula. This allows precise
            positioning of the arrow pointer so that users can easily visualize
            how their sensitivity compare to other Migraine Buddy users
          </>
        ]}
      />
    </>
  );
}

export default PatientReportsShowcase;
