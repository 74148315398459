import MarginNote from '../../base/MarginNote';
import ResumeSectionHeading from '../ResumeSectionHeading';
import UnorderedList from '../../base/UnorderedList';
import React from 'react';
import Heading from '../../base/Heading';
import styles from './index.module.scss';

function SkillsMarginNote() {
  return (
    <MarginNote
      idSuffix="skills"
      label="Skills"
      labelClassName={`sans ${styles.label}`}
    >
      <ResumeSectionHeading className={styles.skillsMarginNoteHeading}>
        Skills
      </ResumeSectionHeading>
      <Heading>Languages</Heading>
      <UnorderedList
        items={[
          'HTML, CSS, SASS, CSS Modules',
          'TypeScript, JavaScript, Python 3',
        ]}
      />
      <Heading>Frameworks</Heading>
      <UnorderedList
        items={[
          'Vue 3, Vue 2, React 16.8',
          'Tailwind, Ant Design, Semantic UI',
          'NestJS, Django',
        ]}
      />
      <Heading>Deployment</Heading>
      <UnorderedList
        items={[
          'AWS API Gateway, Lambda, DynamoDB',
          'AWS Cloudfront, S3,',
          'AWS ECS on Fargate, Docker',
          'NX',
          'Terraform',
        ]}
      />

      <Heading>Database</Heading>
      <UnorderedList items={['PostgresQL', 'NoSQL, MongoDB', 'IndexDB']} />
      <Heading>Tools</Heading>
      <UnorderedList items={['Puppeteer, Framer, Figma']} />
    </MarginNote>
  );
}

export default SkillsMarginNote;
