import React, { Fragment, useEffect, useState } from 'react';
import {
  Content,
  Controls,
  cvContent,
  getContentQueryParameter,
  getControlsQueryParameter,
  getPrintQueryParameter,
  Mode,
  offControls,
  onControls,
  portfolioContent,
  printMode,
  setQueryParameter,
  viewMode,
} from './utils/query.parameter';
import PortfolioContents from './components/applied/PortfolioContents';
import CvContent from './components/applied/CvContent';
import './utils/print.portfolio.access';
import CvPage from './components/applied/CvPage';
import PortfolioPage from './components/applied/PortfolioPage';

const contentQueryParameter = 'content';
const modeQueryParameter = 'mode';
const controlsQueryParameter = 'controls';

const printModeClassName = 'print';
const viewModeClassName = 'view';
const a4PaperSizeClassName = 'A4';
const printStylingClassName =
  'sheet printer-mt-15mm printer-ml-15mm printer-mr-15mm printer-mb-0mm';

const videoFileNameToStartTimeMap = new Map([
  ['kemip-1-card-stack-light-mode.mov', 7.9],
  ['kemip-2-light-and-dark-mode.mov', 0],
  ['kemip-3-record-migraine-with-error-validation.mov', 10],
  ['kemip-4-complete-every-daily-tasks.mp4', 20],
  ['mipap-1-scan-pink-barcode.mov', 2],
  ['mipap-2-scan-yellow-barcode.mov', 3.75],
  ['mipap-3-claim-unredeemed-elapsed-voucher.mov', 3],
  ['mipap-4-did-not-claim-elapsed-unredeemed-voucher.mov', 12.8],
  ['patient-reports-weather-report-bell-curve-with-dynamic-pointer.mov', 7],
  ['patient-support-modules-1-vyepti-buddy-walkthrough.mov', 0],
  ['patient-support-modules-2-doctor-locator.mov', 34],
  ['patient-support-modules-3-accordion-content-card.mov', 3],
  ['questionnaire-engine-1-ubrelvy-survey.mov', 27],
  ['questionnaire-engine-2-taylor-full-flow.mov', 12],
]);

const videoFileNames = Array.from(videoFileNameToStartTimeMap.keys());
const videoStartTimes = Array.from(videoFileNameToStartTimeMap.values());

function App() {
  const [content] = useState<Content>(
    getContentQueryParameter(contentQueryParameter)
  );
  const isCvContent = content === cvContent;
  const isPortfolioContent = portfolioContent.includes(content);
  useEffect(() => {
    setQueryParameter(
      contentQueryParameter,
      isCvContent ? cvContent : 'portfolio'
    );
  }, [isCvContent]);

  const [controls] = useState<Controls>(
    getControlsQueryParameter(controlsQueryParameter)
  );
  const showControls = controls === onControls;
  useEffect(() => {
    setQueryParameter(
      controlsQueryParameter,
      showControls ? onControls : offControls
    );
  }, [showControls]);

  const [mode] = useState<Mode>(getPrintQueryParameter(modeQueryParameter));
  useEffect(() => {
    const videoElements = document.querySelectorAll('video');
    videoElements.forEach((videoElement) => {
      videoElement.controls = showControls;
      const videoIndex = videoFileNames.findIndex((videoFileName) =>
        videoElement.currentSrc.endsWith(videoFileName)
      );
      videoElement.src = `${videoElement.currentSrc}#t=${videoStartTimes[videoIndex]}`;
    });
  }, [showControls]);

  const isPrintMode = mode === printMode;
  useEffect(() => {
    setQueryParameter(modeQueryParameter, isPrintMode ? printMode : viewMode);
  }, [isPrintMode]);

  useEffect(() => {
    if (isPrintMode) {
      document.querySelector('html')?.classList.add(printModeClassName);
      document.querySelector('main')?.classList.add(a4PaperSizeClassName);
    } else {
      document.querySelector('html')?.classList.remove(printModeClassName);
      document.querySelector('main')?.classList.remove(a4PaperSizeClassName);
    }
  }, [isPrintMode]);

  const videoHeight = isPrintMode ? 200 : 500;
  const isViewMode = mode === viewMode;

  return (
    <>
      {isCvContent && (
        <CvPage
          isPrintMode={isPrintMode}
          printStylingClassName={printStylingClassName}
          viewModeClassName={viewModeClassName}
        >
          <CvContent />
        </CvPage>
      )}

      {isPortfolioContent && isPrintMode && (
        <PortfolioContents
          videoHeight={videoHeight}
          portfolioContentMapper={(keyPrefix) =>
            function mapPortfolioContent(PortfolioContent, index) {
              return (
                <PortfolioPage
                  key={`${keyPrefix}-${index}`}
                  isPrintMode={isPrintMode}
                  printStylingClassName={printStylingClassName}
                  viewModeClassName={viewModeClassName}
                >
                  {PortfolioContent}
                </PortfolioPage>
              );
            }
          }
        />
      )}

      {isPortfolioContent && isViewMode && (
        <PortfolioPage
          isPrintMode={isPrintMode}
          printStylingClassName={printStylingClassName}
          viewModeClassName={viewModeClassName}
        >
          <PortfolioContents
            videoHeight={videoHeight}
            portfolioContentMapper={(keyPrefix) =>
              function mapPortfolioContent(PortfolioContent, index) {
                return (
                  <Fragment key={`${keyPrefix}-${index}`}>
                    {PortfolioContent}
                  </Fragment>
                );
              }
            }
          />
        </PortfolioPage>
      )}

      {/*<Paragraph>*/}
      {/*  <IframeContainer>*/}
      {/*    <video controls height="300">*/}
      {/*      <source*/}
      {/*        src="/videos/patient-support-modules-3-accordion-content-card.mov"*/}
      {/*        type="video/webm" />*/}
      {/*      Sorry, your browser does not support embedded videos.*/}
      {/*    </video>*/}
      {/*    <video controls height="300">*/}
      {/*      <source*/}
      {/*        src="/videos/patient-support-modules-2-doctor-locator.mov"*/}
      {/*        type="video/mp4" />*/}
      {/*      Sorry, your browser does not support embedded videos.*/}
      {/*    </video>*/}
      {/*    <video controls height="300">*/}
      {/*      <source*/}
      {/*        src="/videos/walkthrough.mov"*/}
      {/*        type="video/mp4" />*/}
      {/*      Sorry, your browser does not support embedded videos.*/}
      {/*    </video>*/}
      {/*  </IframeContainer>*/}
      {/*  <MarginFigure*/}
      {/*    idSuffix={'clinical-trial-chart'} figure={<img src="/img/progression-overview-1.png" alt="" />}*/}
      {/*    caption={'KEMIP, Nestlé Health Science'} />*/}
      {/*  <MarginFigure*/}
      {/*    idSuffix={'clinical-trial-chart'} figure={<img src="/img/progression-overview-2.png" alt="" />}*/}
      {/*    caption={'KEMIP, Nestlé Health Science'} />*/}
      {/*</Paragraph>*/}
      {/*<Figure>*/}
      {/*  <img src="/img/progression-overview-1.png" alt="" />*/}
      {/*  <img src="/img/progression-overview-2.png" alt="" />*/}
      {/*</Figure>*/}
    </>
  );
}

export default App;
