import React, { ReactNode } from 'react';

interface SectionProps {
  className?: string;
  children: ReactNode;
}

function Section({ className, children }: SectionProps) {
  return <section className={className}>{children}</section>;
}

export default Section;
