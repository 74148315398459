import React, { ReactNode } from 'react';

interface HeadingProps {
  className?: string;
  children: ReactNode;
}

function Heading({ className, children }: HeadingProps) {
  return <h3 className={className}>{children}</h3>;
}

export default Heading;
