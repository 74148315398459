import React, { ReactNode } from 'react';

interface VideoContainerProps {
  className?: string;
  children: ReactNode;
}

function VideoContainer({ className = '', children }: VideoContainerProps) {
  return <div className={`video-wrapper ${className}`}>{children}</div>;
}

export default VideoContainer;
