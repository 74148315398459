import React, { ReactNode } from 'react';
import Article from '../../base/Article';
import Title from '../../base/Title';
import styles from '../../../App.module.scss';
import ResumeSubtitle from '../ResumeSubtitle';
import Section from '../../base/Section';

interface CvPageProps {
  isPrintMode: boolean;
  printStylingClassName: string;
  viewModeClassName: string;
  children: ReactNode;
}

function CvPage({
  isPrintMode,
  children,
  printStylingClassName,
  viewModeClassName,
}: CvPageProps) {
  return (
    <Article
      className={`App ${
        isPrintMode ? printStylingClassName : viewModeClassName
      }`.trim()}
    >
      <Title {...(isPrintMode ? { className: styles.title } : {})}>
        Lim Jiechao
      </Title>

      <ResumeSubtitle
        {...(isPrintMode ? { className: styles.subtitle } : {})}
      />

      <Section>{children}</Section>
    </Article>
  );
}

export default CvPage;
