import React, { SyntheticEvent } from 'react';
import Subtitle from '../../base/Subtitle';
import ContactMarginNote from '../ContactMarginNote';
import SkillsMarginNote from '../SkillsMarginNote';
import EducationMarginNote from '../EducationMarginNote';
import styles from './index.module.scss';

interface ResumeSubtitleProps {
  className?: string;
}

const marginNoteElementIds = Object.freeze([
  'mn-skills',
  'mn-education',
  'mn-contact',
]);

const uncheckOtherMarginNoteToggles = (event: SyntheticEvent) => {
  const targetElement = event.target as HTMLInputElement;
  const targetElementIsInput = targetElement.tagName === 'INPUT';

  if (targetElementIsInput && targetElement.checked) {
    const targetElementId = targetElement.id;
    marginNoteElementIds
      .filter((marginNoteElementId) => marginNoteElementId !== targetElementId)
      .forEach((marginNoteElementId) => {
        const inputElement = document.getElementById(
          marginNoteElementId
        ) as HTMLInputElement;
        inputElement.checked = false;
      });
  }
};

function ResumeSubtitle({ className = '' }: ResumeSubtitleProps) {
  return (
    <Subtitle className={`${className}`.trim()}>
      <div
        className={`${styles.marginNotes}`}
        onClick={uncheckOtherMarginNoteToggles}
      >
        <ContactMarginNote />
        <SkillsMarginNote />
        <EducationMarginNote />
      </div>

      <span className="normal sans">
        A product-minded engineer with over 4 years’ experience crafting B2B and
        B2C mobile web-apps. Interested in the “why”, the business aspect, the
        end-user experience and converging them into a thoughtful and pragmatic
        technical implementation to deliver a product that fits.
      </span>
    </Subtitle>
  );
}

export default ResumeSubtitle;
