import React, { ReactNode } from 'react';

interface ArticleProps {
  className?: string;
  children: ReactNode;
}

function Article({ className, children }: ArticleProps) {
  return <article className={className}>{children}</article>;
}

export default Article;
