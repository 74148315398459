import React, { ReactNode } from 'react';

interface SmallPrint {
  children: ReactNode;
}

function SmallPrint({ children }: SmallPrint) {
  return <span className="small">{children}</span>;
}

export default SmallPrint;
