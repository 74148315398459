import React from 'react';
import ResumeSectionHeading from '../ResumeSectionHeading';
import Heading from '../../base/Heading';
import HealintIntroduction from '../HealintIntroduction';
import CategoryHeading from '../CategoryHeading';
import Paragraph from '../../base/Paragraph';
import Emphasis from '../../base/Emphasis';
import UnorderedList from '../../base/UnorderedList';

const CvContent = () => (
  <>
    <ResumeSectionHeading>Experience</ResumeSectionHeading>

    <Heading>Senior Frontend Developer, Secretlab, July 2022—Present</Heading>

    <CategoryHeading>Sale Management</CategoryHeading>

    <UnorderedList
      items={[
        <>
          Orchestrated an overhaul of sales management for 9 primary e-commerce
          stores to be fully driven by a well-structured and easily extendable
          JSON configuration. Facilitated incremental per-store adoption by
          hiding the revamped code behind feature flag to allow coexistence with
          ongoing sales still running on legacy code.
        </>,
        <>
          Designed the configuration to be fully capable of running
          back-to-back, multi-phase sale themes that can be shared across
          multi-lingual stores while accommodating store-specific sale copy,
          sale exclusions and eligible products.
        </>,
      ]}
    />
    <Heading>Full Stack Developer, Healint, April 2018—May 2022</Heading>

    <HealintIntroduction />

    <CategoryHeading>Patient Support Modules</CategoryHeading>
    {/* Aggregate TCV: USD&nbsp;502,400 */}

    {/*<Paragraph>*/}
    {/*  <SmallCaps>MiPAP module, Novartis</SmallCaps>, Malaysia,{' '}*/}
    {/*  <Duration from="April 2019" to="February 2021" />*/}
    {/*</Paragraph>*/}

    {/*<Paragraph>*/}
    {/*  <SmallCaps>Migraine ACE module, Novartis</SmallCaps>, Philippines,{' '}*/}
    {/*  <Duration from="July 2020" to="December 2020" />*/}
    {/*</Paragraph>*/}

    {/*<Paragraph>*/}
    {/*  <SmallCaps>*/}
    {/*    Nurtec<sup>®</sup> ODT module, Biohaven*/}
    {/*  </SmallCaps>*/}
    {/*  , <Duration from="January 2021" to="ongoing" />*/}
    {/*</Paragraph>*/}

    {/*<Paragraph>*/}
    {/*  <SmallCaps>*/}
    {/*    Vyepti<sup>®</sup> module, Lundbeck*/}
    {/*  </SmallCaps>*/}
    {/*  , United Arab Emirates, Australia, Switzerland & Germany,{' '}*/}
    {/*  <Duration from="October 2021" to="ongoing" />*/}
    {/*</Paragraph>*/}

    {/*<Paragraph>*/}
    {/*  <SmallCaps>Inflammatory Back Pain module, Novartis</SmallCaps>,*/}
    {/*  Malaysia, Philippines, Singapore{' '}*/}
    {/*  <Duration from="June 2020" to="ongoing" />*/}
    {/*</Paragraph>*/}

    <Paragraph>
      <Emphasis>
        Technical lead, full stack developer, UI designer of 5 patient support
        modules. The largest of these modules enabled redemption, procurement
        and coordinated delivery of high-value medication to patients.
      </Emphasis>
    </Paragraph>

    <UnorderedList
      items={[
        <>
          Responsible for the full software development lifecycle and delivery
          ahead of schedule, from exhaustively charting out business workflow
          and its constituent user flows, to deriving API endpoints and
          implementing business logic to orchestrate the workflow
        </>,
        <>
          Seamless cross authentication by project-specific backend with main
          backend with authentication token retrieved from main app without user
          action.
        </>,
        <>
          Set up CI/CD pipeline with infrastructure-as-code Terraform modules to
          deploy the web-app across development, test, and production
          environments
        </>,
        <>
          Crafted a UI component library with 13 input and 23 presentational
          components that was adopted company-wide across 4 projects, reducing
          development time and enforcing consistent look and feel.
        </>,
        <>
          Architect and builder of a system of content blocks such as Markdown,
          video, image, link, and customizable CSS styling fetched and rendered
          from remote configuration for 3 projects.
        </>,
      ]}
    />

    <CategoryHeading>Virtual Clinical Trials</CategoryHeading>
    {/* Aggregate TCV: USD&nbsp;869,954 */}

    {/*<Paragraph>*/}
    {/*  <SmallCaps>KEMIP, Nestlé Health Science,</SmallCaps> Canada,{' '}*/}
    {/*  <Duration from="October 2021" to="July 2022 (projected)" />*/}
    {/*</Paragraph>*/}

    <Paragraph>
      <Emphasis>
        Technical lead, full-stack developer, UI designer in a study conducted
        remotely within a bespoke Migraine Buddy clinical trial module.
      </Emphasis>
    </Paragraph>

    <UnorderedList
      items={[
        <>
          Enhanced a questionnaire engine to dynamically assign user attributes
          to automate patient enrollment and study compliance for pre-screening,
          served to over 16,000 users in 6 months
        </>,
        <>
          Prototyped and built clinical trial web-app and backend to serve as
          daily point of contact, progress to next study stage and seamlessly
          extract records from Migraine Buddy
        </>,
      ]}
    />

    <CategoryHeading>B2B Dashboards</CategoryHeading>

    <Paragraph>
      <Emphasis>
        Engineer of a data-intensive business intelligence explorative web app.
      </Emphasis>
    </Paragraph>

    <UnorderedList
      items={[
        <>
          Forked a basic pivot table and added value filters, heatmaps in table,
          column and row modes, summation by value and percentage etc.
        </>,
        <>
          Refactored the original code to improve the performance. After my
          refactoring, we could comfortably fit 1 million rows in the pivot
          table, while the original implementation started to slow down at
          10,000 rows.
        </>,
      ]}
    />

    <CategoryHeading>B2C Patient Reports</CategoryHeading>

    <Paragraph>
      <Emphasis>
        Artisanal, framework-agnostic craftsman of delightful consumer web apps
      </Emphasis>
    </Paragraph>

    <UnorderedList
      items={[
        <>
          Built two migraine reports to pixel perfection from designer
          prototypes, delivered monthly to over 12,000 subscribers.
        </>,
        <>
          Implemented a meditation module utilizing Web Audio API and a clever
          assortment of CSS animations which can be played and paused
        </>,
      ]}
    />
  </>
);

export default CvContent;
