import Article from '../../base/Article';
import Title from '../../base/Title';
import styles from '../../../App.module.scss';
import Section from '../../base/Section';
import ResumeSectionHeading from '../ResumeSectionHeading';
import React, { ReactNode } from 'react';

interface PortfolioPageProps {
  isPrintMode: boolean;
  printStylingClassName: string;
  viewModeClassName: string;
  children: ReactNode;
}

function PortfolioPage({
  isPrintMode,
  children,
  printStylingClassName,
  viewModeClassName,
}: PortfolioPageProps) {
  return (
    <Article
      className={`App ${
        isPrintMode ? printStylingClassName : viewModeClassName
      }`.trim()}
    >
      <Title {...(isPrintMode ? { className: styles.title } : {})}>
        Lim Jiechao
      </Title>

      <Section>
        <ResumeSectionHeading>Portfolio</ResumeSectionHeading>

        {children}
      </Section>
    </Article>
  );
}

export default PortfolioPage;
