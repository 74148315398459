(async () => {
  const isDevelopment = process.env.NODE_ENV === 'development';

  if (isDevelopment) {
    const portfolioContentAccess = Object.entries<string>(
      JSON.parse(process.env.REACT_APP_PORTFOLIO ?? '{}')
    );
    const contentQueryParameter = 'content';
    const url = new URL(process.env.REACT_APP_PORTFOLIO_URL ?? '');
    const portfolioContentAccessUrls = Object.fromEntries(
      portfolioContentAccess.map(([userId, uuid]) => {
        const contentUrl = new URL(url);
        contentUrl.searchParams.set(contentQueryParameter, uuid);

        return [userId, { uuid, url: contentUrl.toString() }];
      })
    );

    console.table(portfolioContentAccessUrls);
  }
})();

export {};
