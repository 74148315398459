import React from 'react';
import Paragraph from '../../base/Paragraph';
import SmallPrint from '../../base/SmallPrint';

function HealintIntroduction() {
  return (
    <>
      <Paragraph>
        <SmallPrint>
          Healint is the developer of Migraine Buddy, the world-leading migraine
          tracking app with over 3 million downloads and 250,000 MAU. Healint
          also supports virtual clinical trials, delivers pharmaceutical
          business intelligence, and enables medical research with its data
          platform.
        </SmallPrint>
      </Paragraph>

      <Paragraph>
        <SmallPrint>
          In my 4 years at Healint, I’ve been technical lead and developer for 7
          Vue.js web-apps, which have been in production and used by 100,000
          users. I’ve mentored 3 junior developers, teaching them how to create
          applications end to end: from user flows to deployment.
        </SmallPrint>
      </Paragraph>
    </>
  );
}

export default HealintIntroduction;
