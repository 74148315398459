import React, { ReactNode } from 'react';
import Duration from '../Duration';
import Paragraph from '../../base/Paragraph';
import SmallCaps from '../../base/SmallCaps';
import SmallPrint from '../../base/SmallPrint';
import './index.module.scss';

interface OrganizationHeadingProps {
  className?: string;
  children: ReactNode;
  country?: string;
  duration: { from: string; to: string };
}

function OrganizationHeading({
  className = '',
  children,
  country,
  duration: { from, to }
}: OrganizationHeadingProps) {
  return (
    <Paragraph className={className}>
      <SmallCaps>{children}</SmallCaps>,{' '}
      {country && <SmallPrint>{country}</SmallPrint>},{' '}
      <Duration from={from} to={to} />
    </Paragraph>
  );
}

export default OrganizationHeading;
