type WindowResizeListenerCallback = ({
  windowInnerHeight,
  windowInnerWidth
}: {
  windowInnerHeight: number;
  windowInnerWidth: number;
}) => void;

export const mediaQueryBreakpointWidth = 760;

export const useWindowResizeListener = (
  windowResizeListenerCallback: WindowResizeListenerCallback
) => {
  window.addEventListener('resize', (event) => {
    if (event.target) {
      const window = event.currentTarget as Window;

      windowResizeListenerCallback({
        windowInnerHeight: window.innerHeight,
        windowInnerWidth: window.innerWidth
      });
    }
  });
};
