import React, { Fragment, useRef } from 'react';
import { useNanoIdService } from '../../../utils/nanoid';
import Heading from '../../base/Heading';
import ParagraphWithMarginNoteHeight from '../ParagraphWithMarginNoteHeight';
import VideoContainer from '../../base/VideoContainer';
import UnorderedList from '../../base/UnorderedList';
import VideoScrubber from '../../base/VideoScrubber';

interface VyeptiBuddyShowcaseProps {
  videoHeight: number;
}

function VyeptiBuddyShowcase({ videoHeight }: VyeptiBuddyShowcaseProps) {
  const videoElementRef = useRef<HTMLVideoElement | null>(null);
  const nanoIdService = useNanoIdService();
  const prefix = nanoIdService.generate();

  const contentCardVideoScrubs = [
    {
      label: '0:05',
      seconds: 5.1
    },
    {
      label: '0:07',
      seconds: 7
    },
    {
      label: '0:10',
      seconds: 10
    },
    {
      label: '0:12',
      seconds: 12.26
    },
    {
      label: '0:15',
      seconds: 14.48
    },
    {
      label: '0:16',
      seconds: 16.9
    },
    {
      label: '0:20',
      seconds: 20.05
    },
    {
      label: '0:22',
      seconds: 22.105
    },
    {
      label: '0:24',
      seconds: 24
    },
    {
      label: '1:21',
      seconds: 81
    }
  ];

  return (
    <>
      <Heading>Rich Content Cards & Meditation Component</Heading>

      <ParagraphWithMarginNoteHeight
        idSuffix="vyepti-walkthrough"
        figure={
          <VideoContainer>
            <video ref={videoElementRef} controls height={videoHeight}>
              <source
                src="/videos/patient-support-modules-1-vyepti-buddy-walkthrough.mov"
                type="video/mp4"
              />
              Sorry, your browser does not support embedded videos.
            </video>
          </VideoContainer>
        }
        caption={
          <>
            Vyepti® ODT Buddy (United Arab Emirates & Australia, in future
            Switzerland, Germany & Austria), Migraine Buddy&nbsp;app,
            Healint&nbsp;Pte&nbsp;Ltd
          </>
        }
        label="Video"
        labelClassName="sans"
      >
        <UnorderedList
          items={[
            <>
              <VideoScrubber videoElementRef={videoElementRef} seconds={2.5}>
                0:02
              </VideoScrubber>{' '}
              A generic <code>V-Calendar</code> component is meticulously
              restyled to blend in harmoniously with the rest of the component
              library
            </>,
            <>
              A wide variety of rich content cards with action buttons can be
              seen throughout this video (
              {contentCardVideoScrubs.map(
                ({ label, seconds }, index, array) => (
                  <Fragment key={`${prefix}-${index}`}>
                    <VideoScrubber
                      videoElementRef={videoElementRef}
                      seconds={seconds}
                    >
                      {label}
                    </VideoScrubber>
                    <>{array.length !== index + 1 ? ', ' : ''}</>
                  </Fragment>
                )
              )}
              ). Notice that the content blocks cards are virtually
              indistinguishable from hard-coded cards
            </>,
            <>
              <VideoScrubber videoElementRef={videoElementRef} seconds={28}>
                0:28
              </VideoScrubber>{' '}
              Attention is paid to ensure that module interface blends in with
              the meditation component&rsquo;s dark theme
            </>,
            <>
              <VideoScrubber videoElementRef={videoElementRef} seconds={70}>
                1:10
              </VideoScrubber>{' '}
              When leaving the meditation component, the module interface is
              restored to its original theme
            </>,
            <>
              <VideoScrubber videoElementRef={videoElementRef} seconds={32}>
                0:32
              </VideoScrubber>{' '}
              For the meditation component, the animation is achieved using a
              concoction of CSS keyframes of <code>rotate(…)</code> for
              satellite orbiting effect (
              <VideoScrubber videoElementRef={videoElementRef} seconds={34.3}>
                0:34
              </VideoScrubber>
              ), <code>scale(…)</code> for orbit pulsation effect (
              <VideoScrubber videoElementRef={videoElementRef} seconds={34.3}>
                0:34
              </VideoScrubber>
              ) <code>background-color</code> and <code>box-shadow</code> for
              satellite dimming effect (
              <VideoScrubber videoElementRef={videoElementRef} seconds={37}>
                0:37
              </VideoScrubber>{' '}
              and glowing (
              <VideoScrubber videoElementRef={videoElementRef} seconds={41}>
                0:41
              </VideoScrubber>
              )
            </>,
            <>
              There is an escape hatch property to allow custom CSS styling, for
              example, unevenly sized two-column content block (
              <VideoScrubber videoElementRef={videoElementRef} seconds={12.26}>
                0:12
              </VideoScrubber>
              ), text wrapping around floating image (
              <VideoScrubber videoElementRef={videoElementRef} seconds={7}>
                0:07
              </VideoScrubber>
              ,{' '}
              <VideoScrubber videoElementRef={videoElementRef} seconds={14.48}>
                0:14
              </VideoScrubber>
              )
            </>,
            <>
              There is extensive component reuse, for e.g., the calendar event
              list item (
              <VideoScrubber videoElementRef={videoElementRef} seconds={2.8}>
                0:02
              </VideoScrubber>
              ) and the options in the Settings section (
              <VideoScrubber videoElementRef={videoElementRef} seconds={77}>
                1:17
              </VideoScrubber>
              ) are using the same base component but the former resides in a
              card while the latter sits directly in the view but both look
              perfectly at home.
            </>
          ]}
        />
      </ParagraphWithMarginNoteHeight>
    </>
  );
}

export default VyeptiBuddyShowcase;
