import React, { ForwardedRef, ReactNode } from 'react';

interface ParagraphProps {
  forwardRef?: ForwardedRef<HTMLParagraphElement>;
  className?: string;
  children: ReactNode;
}

function Paragraph({ forwardRef, className, children }: ParagraphProps) {
  return (
    <p {...(forwardRef ? { ref: forwardRef } : {})} className={className}>
      {children}
    </p>
  );
}

export default Paragraph;
