import React, { ForwardedRef, ReactNode } from 'react';
import styles from './index.module.scss';

export interface MarginNoteProps {
  forwardRef?: ForwardedRef<HTMLDivElement>;
  idSuffix: string;
  className?: string;
  children: ReactNode;
  label?: string;
  labelClassName?: string;
}

const idPrefix = 'mn';

function MarginNote({
  forwardRef,
  idSuffix,
  className = '',
  children,
  label = '',
  labelClassName = ''
}: MarginNoteProps) {
  return (
    <span className={`marginnote-container ${styles.marginNote}`}>
      <input
        type="checkbox"
        id={`${idPrefix}-${idSuffix}`}
        className="margin-toggle"
      />

      <label htmlFor={`${idPrefix}-${idSuffix}`} className="margin-toggle">
        <span className="toggle-symbol" />
        {label && (
          <span className={`${styles.label} ${labelClassName}`}>{label}</span>
        )}
      </label>

      <span
        {...(forwardRef ? { ref: forwardRef } : {})}
        className={`marginnote ${className}`.trim()}
      >
        {children}
      </span>
    </span>
  );
}

export default MarginNote;
