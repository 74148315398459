import React, { useRef } from 'react';
import Heading from '../../base/Heading';
import ParagraphWithMarginNoteHeight from '../ParagraphWithMarginNoteHeight';
import VideoContainer from '../../base/VideoContainer';
import UnorderedList from '../../base/UnorderedList';
import VideoScrubber from '../../base/VideoScrubber';

interface DoctorLocatorComponentShowcaseProps {
  videoHeight: number;
}

function DoctorLocatorComponentShowcase({
  videoHeight
}: DoctorLocatorComponentShowcaseProps) {
  const videoElementRef = useRef<HTMLVideoElement | null>(null);

  return (
    <>
      <Heading>Doctor Locator Component</Heading>

      <ParagraphWithMarginNoteHeight
        idSuffix="doctor-locator"
        figure={
          <VideoContainer>
            <video ref={videoElementRef} controls height={videoHeight}>
              <source
                src="/videos/patient-support-modules-2-doctor-locator.mov"
                type="video/mp4"
              />
              Sorry, your browser does not support embedded videos.
            </video>
          </VideoContainer>
        }
        caption={
          <>
            Rheumatologist Locator (Malaysia), Inflammatory Back Pain module,
            Clarrio&nbsp;app, Healint&nbsp;Pte&nbsp;Ltd
          </>
        }
        label="Video"
        labelClassName="sans"
      >
        <UnorderedList
          items={[
            <>
              <VideoScrubber videoElementRef={videoElementRef} seconds={0}>
                0:00
              </VideoScrubber>{' '}
              Search card features configurable search options by doctor
              specialization, location type and distance
            </>,
            <>
              <VideoScrubber videoElementRef={videoElementRef} seconds={0}>
                0:00
              </VideoScrubber>{' '}
              Video shows the Doctor Locator with only the distance option as
              doctor specialization and location type are fixed
            </>,
            <>
              <VideoScrubber videoElementRef={videoElementRef} seconds={4}>
                0:04
              </VideoScrubber>{' '}
              Users can copy the address and contact number by pressing the copy
              icon on the right
            </>,
            <>
              <VideoScrubber videoElementRef={videoElementRef} seconds={3}>
                0:03
              </VideoScrubber>{' '}
              Users can call the doctor by pressing the button at the bottom of
              the result card
            </>,
            <>
              <VideoScrubber videoElementRef={videoElementRef} seconds={54}>
                0:54
              </VideoScrubber>{' '}
              Search results is paginated when there are too many to be shown in
              one page without a performance hit
            </>,
            <>
              As of May 2022, it is used across 2 projects and waiting to be
              backported into the source project should development resource be
              available
            </>
          ]}
        />
      </ParagraphWithMarginNoteHeight>
    </>
  );
}

export default DoctorLocatorComponentShowcase;
