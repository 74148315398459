import React, { ReactNode } from 'react';

interface SubtitleProps {
  className?: string;
  children: ReactNode;
}

function Subtitle({ className, children }: SubtitleProps) {
  return <p className={`subtitle ${className}`.trim()}>{children}</p>;
}

export default Subtitle;
