import React, { useRef } from 'react';
import Heading from '../../base/Heading';
import Figure from '../../base/Figure';
import { useNanoIdService } from '../../../utils/nanoid';
import styles from './index.module.scss';
import Paragraph from '../../base/Paragraph';
import UnorderedList from '../../base/UnorderedList';

function MipapShowcase() {
  const videoElementRefs = useRef<(HTMLVideoElement | null)[]>([]);
  const videoFileNames = [
    'mipap-1-scan-pink-barcode.mov',
    'mipap-2-scan-yellow-barcode.mov',
    'mipap-3-claim-unredeemed-elapsed-voucher.mov',
    'mipap-4-did-not-claim-elapsed-unredeemed-voucher.mov'
  ];
  const nanoIdService = useNanoIdService();
  const prefix = nanoIdService.generate();

  return (
    <>
      <Heading>Migraine Patient Assistance Programme, Malaysia</Heading>

      <Paragraph>
        This was my first solo full-stack project. I went all out, from project
        managing, frontend, backend to deployment, cradle to grave.
      </Paragraph>

      <Figure className={styles.videoQuilt} isFullWidth>
        {videoFileNames.map((videoFileName, index) => (
          <video
            key={`${prefix}-${index}`}
            ref={(ref) => {
              videoElementRefs.current.push(ref);
            }}
            controls
            width="100%"
          >
            <source src={`/videos/${videoFileName}`} type="video/mp4" />
            Sorry, your browser does not support embedded videos.
          </video>
        ))}
      </Figure>

      <UnorderedList
        items={[
          <>
            A variety of animations, for example, shaking buttons, pulsating
            graphic were used to direct the user to what they should be looking
            at.
          </>,
          <>
            Many custom SVGs, composed with off-the-shelf icons, were used. The
            graphic of a hand holding smartphone scanning a bar code, was
            assembled from a hand holding a burger, a bar code, and a
            smartphone. The interface shown on the phone was deliberately made
            to look like the actual camera view scanning barcode.
          </>,
          <>
            Note that the same barcode is echoed in the leftmost button of the
            bottom navigation bar, while the purple syringe voucher in the
            middle button is echoed in the promotion banner at the top.
          </>
        ]}
      />
    </>
  );
}

export default MipapShowcase;
