import React, { ReactNode } from 'react';
import { useNanoIdService } from '../../../utils/nanoid';

interface UnorderedListProps {
  className?: string;
  items: ReactNode[];
}

function UnorderedList({ className, items }: UnorderedListProps) {
  const nanoIdService = useNanoIdService();
  const prefix = nanoIdService.generate();

  return (
    <ul className={className}>
      {items.map((item, index) => (
        <li key={`${prefix}-${index}`}>{item}</li>
      ))}
    </ul>
  );
}

export default UnorderedList;
