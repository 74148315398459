import React from 'react';
import styles from './index.module.scss';
import Figure from '../../base/Figure';

interface ImageThumbnailProps {
  className: string;
  src: string;
  title?: string;
  alt?: string;
}

function ImageThumbnail({
  className,
  src,
  title = '',
  alt = ''
}: ImageThumbnailProps) {
  const openInNewWindow = () => {
    window.open(src, '_blank');
  };

  return (
    <img
      onClick={openInNewWindow}
      className={className}
      src={src}
      title={title}
      alt={alt}
    />
  );
}

function PatientReportQuilt() {
  return (
    <Figure className={styles.quilt} isFullWidth>
      <ImageThumbnail
        className={styles.triggersOne}
        src="/img/report-triggers-page-1.png"
        title="Triggers Report Page 1"
      />
      <ImageThumbnail
        className={styles.triggersTwo}
        src="/img/report-triggers-page-2.png"
        title="Triggers Report Page 2"
      />
      <ImageThumbnail
        className={styles.triggersThree}
        src="/img/report-triggers-page-3.png"
        title="Triggers Report Page 3"
      />
      <ImageThumbnail
        className={styles.triggersFour}
        src="/img/report-triggers-page-4.png"
        title="Triggers Report Page 4"
      />
      <ImageThumbnail
        className={styles.weatherOne}
        src="/img/report-weather-page-1.png"
        title="Weather Report Page 1"
      />
      <ImageThumbnail
        className={styles.weatherTwo}
        src="/img/report-weather-page-2.png"
        title="Weather Report Page 2"
      />
      <ImageThumbnail
        className={styles.weatherThree}
        src="/img/report-weather-page-3.png"
        title="Weather Report Page 3"
      />
      <ImageThumbnail
        className={styles.weatherFour}
        src="/img/report-weather-page-4.png"
        title="Weather Report Page 4"
      />
      <ImageThumbnail
        className={styles.weatherFive}
        src="/img/report-weather-page-5.png"
        title="Weather Report Page 5"
      />
      <ImageThumbnail
        className={styles.weatherSix}
        src="/img/report-weather-page-6.png"
        title="Weather Report Page 6"
      />
      <ImageThumbnail
        className={styles.weatherSeven}
        src="/img/report-weather-page-7.png"
        title="Weather Report Page 7"
      />
    </Figure>
  );
}

export default PatientReportQuilt;
