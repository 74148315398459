import React, { useRef } from 'react';
import styles from './index.module.scss';
import Figure from '../../base/Figure';
import { useNanoIdService } from '../../../utils/nanoid';
import Heading from '../../base/Heading';
import UnorderedList from '../../base/UnorderedList';

function QuestionnaireShowcase() {
  const videoElementRefs = useRef<(HTMLVideoElement | null)[]>([]);
  const videoFileNames = [
    'questionnaire-engine-1-ubrelvy-survey.mov',
    'questionnaire-engine-2-taylor-full-flow.mov'
  ];
  const nanoIdService = useNanoIdService();
  const prefix = nanoIdService.generate();

  return (
    <>
      <Heading>Survey Engine Component</Heading>

      <Figure className={styles.videoQuilt}>
        {videoFileNames.map((videoFileName, index) => (
          <video
            key={`${prefix}-${index}`}
            ref={(ref) => {
              videoElementRefs.current.push(ref);
            }}
            controls
            width="100%"
          >
            <source src={`/videos/${videoFileName}`} type="video/mp4" />
            Sorry, your browser does not support embedded videos.
          </video>
        ))}
      </Figure>

      <UnorderedList
        items={[
          <>
            Left video shows the battle-tested Vue 3 version, right video shows
            the latest reincarnation in React with ample sequential entrance and
            exit transitions
          </>,
          <>
            The Vue 3 version exists as a standalone deployment as well as a
            component directly embedded into modules for a more seamless
            experience
          </>,
          <>
            Many custom question types have been created to meet the needs of
            clinical studies
          </>
        ]}
      />
    </>
  );
}

export default QuestionnaireShowcase;
