import React, { useRef } from 'react';
import Heading from '../../base/Heading';
import Figure from '../../base/Figure';
import { useNanoIdService } from '../../../utils/nanoid';
import styles from './index.module.scss';
import Paragraph from '../../base/Paragraph';
import UnorderedList from '../../base/UnorderedList';

function KemipShowcase() {
  const videoElementRefs = useRef<(HTMLVideoElement | null)[]>([]);
  const videoFileNames = [
    'kemip-1-card-stack-light-mode.mov',
    'kemip-2-light-and-dark-mode.mov',
    'kemip-3-record-migraine-with-error-validation.mov',
    'kemip-4-complete-every-daily-tasks.mp4'
  ];
  const nanoIdService = useNanoIdService();
  const prefix = nanoIdService.generate();

  return (
    <>
      <Heading>KEMIP, Nestlé Health Science, Canada</Heading>

      <Paragraph>
        This was a high-value full-stack project. It was built concurrently with
        the underlying component library.
      </Paragraph>

      <Figure className={styles.videoQuilt} isFullWidth>
        {videoFileNames.map((videoFileName, index) => (
          <video
            key={`${prefix}-${index}`}
            ref={(ref) => {
              videoElementRefs.current.push(ref);
            }}
            controls
            width="100%"
          >
            <source src={`/videos/${videoFileName}`} type="video/mp4" />
            Sorry, your browser does not support embedded videos.
          </video>
        ))}
      </Figure>

      <UnorderedList
        items={[
          <>
            The blinking man in the study progression chevron is the first thing
            that catches the user’s attention in the study module main view. The
            intention was to invite the user to read the top card which serves
            as a summary of where the user is in the study as well as the list
            of daily tasks to be completed in order to be fully compliant. This
            component is a custom SVG creation and animated with CSS.
          </>,
          <>
            The traffic light calendar visualizes the user’s progress within a
            study phase. When there are outstanding tasks for a day, a yellow
            circle with a pencil is shown. When all tasks are done, a green
            circle with a tick is earned. If the user fails to complete all the
            daily tasks, a red circle with a cross is shown. This design strives
            to motivate users to finish all the daily tasks in order to earn a
            “sea of green circles with approval ticks”. Component is a custom
            creation as no such off-the-shelf calendar component exists.
          </>,
          <>
            A lot of attention was paid to the component shadows, gradient
            background, text colors and spacing to ensure everything composes
            well and nothing looks out of place. The graphics, all SVGs, were
            painstakingly curated and in some cases, tweaked and recomposed so
            that they all look handpicked for the occasion and sit well with one
            another in the overall aesthetics.
          </>
        ]}
      />
    </>
  );
}

export default KemipShowcase;
