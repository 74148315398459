import React, { ReactNode, useEffect, useRef, useState } from 'react';
import Paragraph from '../../base/Paragraph';
import MarginFigure, { MarginFigureProps } from '../../base/MarginFigure';
import {
  mediaQueryBreakpointWidth,
  useWindowResizeListener
} from '../../../utils/window.resize.listener';

interface ParagraphWithMarginNoteHeightProps
  extends Exclude<MarginFigureProps, 'forwardRef'> {
  children: ReactNode;
}

function ParagraphWithMarginNoteHeight({
  idSuffix,
  figure,
  caption,
  label,
  labelClassName,
  children
}: ParagraphWithMarginNoteHeightProps) {
  const [hasFixedHeight, setHasFixedHeight] = useState(false);
  useEffect(() => {
    setHasFixedHeight(mediaQueryBreakpointWidth <= window.innerWidth);
  }, []);
  useWindowResizeListener(({ windowInnerWidth }) => {
    setHasFixedHeight(mediaQueryBreakpointWidth <= windowInnerWidth);
  });

  const marginNoteRef = useRef<HTMLDivElement | null>(null);
  const [marginNoteParagraphHeight, setMarginNoteParagraphHeight] =
    useState<number>(NaN);
  const marginNoteParagraphRef = useRef<HTMLParagraphElement | null>(null);
  useEffect(() => {
    if (marginNoteRef.current) {
      setMarginNoteParagraphHeight(marginNoteRef.current?.offsetHeight ?? NaN);
    }
  });
  useEffect(() => {
    if (marginNoteParagraphRef.current) {
      const paragraphOnlyHeight =
        marginNoteParagraphRef.current?.offsetHeight ?? 0;
      const paragraphWithMarginNoteHeight =
        paragraphOnlyHeight > marginNoteParagraphHeight
          ? paragraphOnlyHeight
          : marginNoteParagraphHeight;
      marginNoteParagraphRef.current.style.height = hasFixedHeight
        ? `${paragraphWithMarginNoteHeight}px`
        : '';
    }
  }, [marginNoteParagraphHeight, hasFixedHeight]);

  return (
    <Paragraph forwardRef={marginNoteParagraphRef}>
      <MarginFigure
        forwardRef={marginNoteRef}
        idSuffix={idSuffix}
        figure={figure}
        caption={caption}
        {...(label ? { label } : {})}
        {...(label ? { labelClassName } : {})}
      />
      {children}
    </Paragraph>
  );
}

export default ParagraphWithMarginNoteHeight;
