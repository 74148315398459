import React, { HTMLAttributeAnchorTarget, ReactNode } from 'react';

interface LinkProps {
  className?: string;
  link: URL | `tel:${string}` | `mailto:${string}`;
  target: Extract<
    HTMLAttributeAnchorTarget,
    '_self' | '_blank' | '_top' | '_parent'
  >;
  children: ReactNode;
}

function Link({ className, link, target = '_blank', children }: LinkProps) {
  return (
    <a href={link.toString()} className={className} target={target}>
      {children}
    </a>
  );
}

export default Link;
