import React, { useRef } from 'react';
import Heading from '../../base/Heading';
import ParagraphWithMarginNoteHeight from '../ParagraphWithMarginNoteHeight';
import VideoContainer from '../../base/VideoContainer';
import UnorderedList from '../../base/UnorderedList';
import VideoScrubber from '../../base/VideoScrubber';

interface ContentBlockLibraryShowcaseProps {
  videoHeight: number;
}

function ContentBlockLibraryShowcase({
  videoHeight
}: ContentBlockLibraryShowcaseProps) {
  const videoElementRef = useRef<HTMLVideoElement | null>(null);

  return (
    <>
      <Heading>Accordion & Two-column Content Blocks</Heading>

      <ParagraphWithMarginNoteHeight
        idSuffix="accordion-and-two-column"
        figure={
          <VideoContainer>
            <video ref={videoElementRef} controls height={videoHeight}>
              <source
                src="/videos/patient-support-modules-3-accordion-content-card.mov"
                type="video/mp4"
              />
              Sorry, your browser does not support embedded videos.
            </video>
          </VideoContainer>
        }
        caption={
          <>
            Explore Section, Inflammatory Back Pain module (Philippines,
            Malaysia & Singapore), Clarrio&nbsp;app, Healint&nbsp;Pte&nbsp;Ltd
          </>
        }
        label="Video"
        labelClassName="sans"
      >
        <UnorderedList
          items={[
            <>
              This content block is built from an accordion component which
              seamlessly transitions when content is expanded (
              <VideoScrubber videoElementRef={videoElementRef} seconds={2}>
                0:02
              </VideoScrubber>
              ) or collapsed (
              <VideoScrubber videoElementRef={videoElementRef} seconds={5}>
                0:05
              </VideoScrubber>
              ).
            </>,
            <>
              The down and up caret icons in the accordion item heading, apart
              from providing affordance, are animated to rotate clockwise and
              counter-clockwise to provide a delightful distraction while
              content expands or collapses
            </>,
            <>
              The accordion content features a (
              <VideoScrubber videoElementRef={videoElementRef} seconds={4}>
                0:04
              </VideoScrubber>
              ) two-column content block built with CSS grid. It is able to
              render contents such as Markdown, images, videos and buttons.
            </>,
            <>
              These two content blocks are surprisingly popular among clients.
            </>
          ]}
        />
      </ParagraphWithMarginNoteHeight>
    </>
  );
}

export default ContentBlockLibraryShowcase;
