export const cvContent = 'cv';
export const portfolioContent: string[] = Object.values(
  JSON.parse(process.env.REACT_APP_PORTFOLIO ?? '{}')
);
export type Content = 'cv' | typeof portfolioContent[number];

export function getContentQueryParameter(key: string) {
  const value = new URL(window.location.href).searchParams.get(key) ?? '';

  switch (true) {
    case portfolioContent.includes(value):
      return value;
    case value === cvContent:
    default:
      return cvContent;
  }
}

export type Mode = 'view' | 'print';
export const printMode: Mode = 'print';
export const viewMode: Mode = 'view';

export function getPrintQueryParameter(key: string) {
  const value = new URL(window.location.href).searchParams.get(key);

  switch (value) {
    case printMode:
      return printMode;
    case viewMode:
    default:
      return viewMode;
  }
}

export type Controls = 'on' | 'off';
export const onControls: Controls = 'on';
export const offControls: Controls = 'off';

export function getControlsQueryParameter(key: string) {
  const value = new URL(window.location.href).searchParams.get(key);

  switch (value) {
    case onControls:
      return onControls;
    case offControls:
    default:
      return offControls;
  }
}

export function setQueryParameter(key: string, value: string) {
  const url = new URL(window.location.href);
  url.searchParams.set(key, value);
  window.history.replaceState({}, '', url);
}
