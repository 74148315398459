import React, { ReactNode } from 'react';
import Heading from '../../base/Heading';

interface CategoryHeadingProps {
  className?: string;
  children: ReactNode;
}

function CategoryHeading({ className = '', children }: CategoryHeadingProps) {
  return (
    <Heading className={`danger sans normal ${className}`.trim()}>
      {children}
    </Heading>
  );
}

export default CategoryHeading;
