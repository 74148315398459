import React, { ForwardedRef, ReactNode } from 'react';
import MarginNote, { MarginNoteProps } from '../MarginNote';

export interface MarginFigureProps {
  forwardRef?: ForwardedRef<HTMLDivElement>;
  className?: MarginNoteProps['className'];
  idSuffix: MarginNoteProps['idSuffix'];
  figure: ReactNode;
  caption: ReactNode;
  label?: string;
  labelClassName?: string;
}

function MarginFigure({
  forwardRef,
  className,
  idSuffix,
  figure,
  caption,
  label,
  labelClassName
}: MarginFigureProps) {
  return (
    <MarginNote
      className={className}
      idSuffix={idSuffix}
      {...(forwardRef ? { forwardRef } : {})}
      {...(label ? { label } : {})}
      {...(label ? { labelClassName } : {})}
    >
      {figure}

      {caption}
    </MarginNote>
  );
}

export default MarginFigure;
