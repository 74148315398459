import React, { ReactNode } from 'react';

interface EmphasisProps {
  className?: string;
  children: ReactNode;
}

function Emphasis({ className, children }: EmphasisProps) {
  return <em className={className}>{children}</em>;
}

export default Emphasis;
