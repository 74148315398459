import React, { Fragment } from 'react';
import Emphasis from '../../base/Emphasis';
import { useNanoIdService } from '../../../utils/nanoid';
import styles from './index.module.scss';

export interface DurationProps {
  className?: string;
  from: string;
  to: string;
}

function Duration({ className = '', from, to }: DurationProps) {
  const nanoIdService = useNanoIdService();
  const prefix = nanoIdService.generate();

  const replaceWhiteSpaceWithNonBreakingSpace = (string: string) =>
    string.split(' ').map((substring, index, substrings) => (
      <Fragment key={`${prefix}-${index}`}>
        {substring}
        {substrings.length - 1 === index ? <></> : <>&#160;</>}
      </Fragment>
    ));

  return (
    <Emphasis className={`small ${styles.duration} ${className}`.trim()}>
      {replaceWhiteSpaceWithNonBreakingSpace(from)}—
      {replaceWhiteSpaceWithNonBreakingSpace(to)}
    </Emphasis>
  );
}

export default Duration;
