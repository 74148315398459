import React from 'react';
import MarginNote from '../../base/MarginNote';
import ResumeSectionHeading from '../ResumeSectionHeading';
import UnorderedList from '../../base/UnorderedList';
import Link from '../../base/Link';
import styles from './index.module.scss';

const email = 'jiechao.lim@gmail.com';
const linkedIn = 'https://www.linkedin.com/in/jiechao-lim';
const github = 'https://github.com/limjiechao';
const mobileNumber = '+65-9776-6034';

function ContactMarginNote() {
  return (
    <MarginNote
      idSuffix="contact"
      label="Contact"
      labelClassName={`sans ${styles.label}`}
    >
      <ResumeSectionHeading className={styles.contactMarginNoteHeading}>
        Contact
      </ResumeSectionHeading>
      <UnorderedList
        items={[
          <Link key="email" link={`mailto:${email}`} target={'_self'}>
            {email}
          </Link>,
          <Link key="linkedIn" link={new URL(linkedIn)} target="_blank">
            linkedin.com/in/jiechao-lim
          </Link>,
          <Link key="github" link={new URL(github)} target="_blank">
            github.com/limjiechao
          </Link>,
          <Link key="mobile" link={`tel:${mobileNumber}`} target="_parent">
            {mobileNumber}
          </Link>
        ]}
      />
    </MarginNote>
  );
}

export default ContactMarginNote;
