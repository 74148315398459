import React, { ReactNode } from 'react';
import MarginNote from '../../base/MarginNote';
import ResumeSectionHeading from '../ResumeSectionHeading';
import OrganizationHeading from '../OrganizationHeading';
import SmallPrint from '../../base/SmallPrint';
import Heading from '../../base/Heading';
import styles from '../SkillsMarginNote/index.module.scss';

interface EducationHeadingProps {
  children: ReactNode;
}

const EducationHeading = ({ children }: EducationHeadingProps) => (
  <Heading className="sans normal">{children}</Heading>
);

function EducationMarginNote() {
  return (
    <MarginNote
      idSuffix="education"
      label="Education"
      labelClassName={`sans ${styles.label}`}
    >
      <ResumeSectionHeading className={styles.skillsMarginNoteHeading}>
        Education
      </ResumeSectionHeading>
      <EducationHeading>Web Development Immersive</EducationHeading>
      <OrganizationHeading
        country="Singapore"
        duration={{ from: 'November 2017', to: 'February 2018' }}
      >
        General Assembly
      </OrganizationHeading>

      <EducationHeading>
        B.Sc <SmallPrint>(Hons)</SmallPrint> Applied Accounting
      </EducationHeading>
      <OrganizationHeading
        country="Singapore"
        duration={{ from: '2007', to: '2008' }}
      >
        Oxford Brookes University
      </OrganizationHeading>
    </MarginNote>
  );
}

export default EducationMarginNote;
