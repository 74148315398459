import React, { Fragment } from 'react';
import CategoryHeading from '../CategoryHeading';
import Paragraph from '../../base/Paragraph';
import Emphasis from '../../base/Emphasis';
import UnorderedList from '../../base/UnorderedList';
import KemipShowcase from '../KemipShowcase';
import PatientReportQuilt from '../PatientReportQuilt';
import PatientReportsShowcase from '../PatientReportsShowcase';
import VyeptiBuddyShowcase from '../VyeptiBuddyShowcase';
import DoctorLocatorComponentShowcase from '../DoctorLocatorComponentShowcase';
import ContentBlockLibraryShowcase from '../ContentBlockLibraryShowcase';
import MipapShowcase from '../MipapShowcase';
import QuestionnaireShowcase from '../QuestionnaireShowcase';
import { useNanoIdService } from '../../../utils/nanoid';

const PortfolioFragments = (videoHeight: number) => [
  <Fragment key="virtual-clinical-trials">
    <CategoryHeading>Virtual Clinical Trials</CategoryHeading>

    <Paragraph>
      <Emphasis>Component Library</Emphasis>
    </Paragraph>

    <UnorderedList
      items={[
        <>
          This component library was built from ground up with Vue 3,
          TypeScript, Tailwind CSS, as well as vanilla HTML and CSS snippets
          adapted from Tailwind UI
        </>,
        <>
          It has light and dark mode which can be toggled with CSS{' '}
          <code>dark</code> class
        </>,
        <>
          As of May 2022, it has 23 presentational and 13 input components and
          has been used across 4 projects
        </>,
      ]}
    />

    <KemipShowcase />
  </Fragment>,
  <Fragment key="patient-reports">
    <CategoryHeading>Patient Reports</CategoryHeading>

    <Paragraph>
      These monthly premium reports, delivered to over 10,000 MBplus subscribers
      of the Migraine Buddy app, were implemented to such a level of fidelity it
      is virtually indistinguishable when placed beside designer prototypes.
    </Paragraph>

    <PatientReportQuilt />

    <PatientReportsShowcase />
  </Fragment>,
  <Fragment key="patient-support-modules-page-1">
    <CategoryHeading>Patient Support Modules</CategoryHeading>

    <Paragraph>
      <Emphasis>
        Content Blocks, Component Library & Modular Components
      </Emphasis>
    </Paragraph>

    <Paragraph>
      Patient support modules are recurring low-value (~USD&nbsp;80,000) B2B
      contracts which do not justify time and effort in a large amount of
      project-specific code. The solution was to build a library of reusable
      content blocks with the aforementioned component library using the
      versatile card metaphor popularized by Google.
    </Paragraph>

    <Paragraph>
      The following videos showcase reusable components from the content block
      library to allow efficient development of polished modules that is
      adaptable to clients&rsquo; needs.
    </Paragraph>

    <VyeptiBuddyShowcase videoHeight={videoHeight} />

    <DoctorLocatorComponentShowcase videoHeight={videoHeight} />
  </Fragment>,
  <Fragment key="patient-support-modules-page-2">
    <CategoryHeading>Patient Support Modules</CategoryHeading>

    <Paragraph>
      <Emphasis>
        Content Blocks, Component Library & Modular Components
      </Emphasis>
    </Paragraph>

    <ContentBlockLibraryShowcase videoHeight={videoHeight} />

    <MipapShowcase />
  </Fragment>,
  <Fragment key="questionnaire-engine">
    <CategoryHeading>Questionnaire Engine</CategoryHeading>

    <Paragraph>
      Based on Typeform, the questionnaire engine has been used in clinical
      studies for prescreening and scheduled patient reported outcomes as well
      as for onboarding in patient support modules.
    </Paragraph>

    <QuestionnaireShowcase />
  </Fragment>,
];

interface PortfolioContentsProps {
  videoHeight: number;
  portfolioContentMapper: (
    keyPrefix: string
  ) => (PortfolioContent: JSX.Element, index: number) => JSX.Element;
}

function PortfolioContents({
  videoHeight,
  portfolioContentMapper,
}: PortfolioContentsProps) {
  const PortfolioContents = PortfolioFragments(videoHeight);
  const nanoIdService = useNanoIdService();
  const keyPrefix = nanoIdService.generate();

  return <>{PortfolioContents.map(portfolioContentMapper(keyPrefix))}</>;
}

export default PortfolioContents;
