import React, { ReactNode } from 'react';

interface SmallCapsProps {
  className?: string;
  children: ReactNode;
}

function SmallCaps({ className = '', children }: SmallCapsProps) {
  return <span className={`newthought ${className}`.trim()}>{children}</span>;
}

export default SmallCaps;
