import React, { Ref } from 'react';
import { ReactComponent as Seek } from '../../../icons/video.svg';
import styles from './index.module.scss';

interface VideoScrubberProps {
  className?: string;
  videoElementRef: Ref<HTMLMediaElement>;
  children: string;
  seconds: number;
}

function VideoScrubber({
  className,
  videoElementRef,
  children,
  seconds
}: VideoScrubberProps) {
  const scrubVideo = () => {
    if (videoElementRef) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      videoElementRef.current.currentTime = seconds;
    }
  };
  return (
    <span
      className={`${styles.videoScrubber} tufte-underline ${className}`.trim()}
      onClick={scrubVideo}
    >
      <Seek className={styles.seek} /> <code>{children}</code>
    </span>
  );
}

export default VideoScrubber;
