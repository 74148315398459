import React, { ReactNode } from 'react';
import MarginNote, { MarginNoteProps } from '../MarginNote';

interface FigureProps {
  className?: string;
  marginNote?: MarginNoteProps;
  isFullWidth?: boolean;
  children: ReactNode;
}

function Figure({
  className = '',
  marginNote,
  isFullWidth = false,
  children
}: FigureProps) {
  return (
    <figure
      className={`${isFullWidth ? 'fullwidth' : ''} ${
        marginNote ? 'has-margin-note' : ''
      } ${className}`.trim()}
    >
      {marginNote && (
        <MarginNote
          idSuffix={marginNote?.idSuffix}
          className={marginNote.className}
        >
          {marginNote?.children}
        </MarginNote>
      )}

      {children}
    </figure>
  );
}

export default Figure;
