import React, { ReactNode } from 'react';
import Heading from '../../base/Heading';
import styles from './index.module.scss';

interface ResumeSectionHeadingProps {
  className?: string;
  children: ReactNode;
}

function ResumeSectionHeading({
  className = '',
  children
}: ResumeSectionHeadingProps) {
  return (
    <Heading
      className={`${styles.resumeSectionHeading} sans normal uppercase ${className}`.trim()}
    >
      {children}
    </Heading>
  );
}

export default ResumeSectionHeading;
